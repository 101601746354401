import { DefaultLayout } from '../layouts/DefaultLayout';
import { Seo } from '@/Seo';
import { StaticImage } from 'gatsby-plugin-image';
import styled from '@emotion/styled';
import { colors, device } from '@/layout/GlobalStyles';
import { Link, graphql } from 'gatsby';

const StyledExperience = styled.div`
    padding: 10em 1em 4em;
    a {
        color: ${colors.blueLight};
    }
    p {
        margin: 1em 0em;
    }

    button {
        margin: 2em 0em;
    }
    @media ${device.tablet} {
        margin: 0 auto;
        max-width: 620px;
        padding: 8em 1em 2em;
        .buttons {
            max-width: 680px;
            padding: 1em;
            margin: 2em 0em;
            display: flex;
            justify-content: space-around;
            button {
                margin: 2em;
            }
        }
        section {
            display: flex;
            p {
                padding-right: 2em;
                max-width: 35vw;
            }
        }
        button {
            margin: 4em auto 2em;
        }
    }
    @media ${device.laptop} {
        margin: 0 auto;
        max-width: 1340px;
        padding: 4em 1em 0em;
        section {
            display: flex;
            width: 1320px;
            img {
                margin-top: 1em;
                height: 280px;
            }
            p {
                padding-right: 2em;
            }
        }
        button {
            margin: 4em 0em 2em;
        }
    }
`;

const Experience: Page = ({ data }) => {
    const heroImage = data.allFile.edges[0].node.childImageSharp.gatsbyImageData;
    return (
        <main>
            <DefaultLayout
                title="Your Experience"
                heroImage={heroImage}
                heroText="Feel free to give us feedback on any of our services or experiences you have had with us!"
                // heroText={data.mdx.frontmatter.heroText}
            >
                <Seo
                    title="Health Covid Information | Bloom La Vie Health"
                    description="The Front Line COVID-19 Critical Care Alliance (FLCCC) publishes updates surrounding the most effective prevention & early treatment protocols to counter COVID-19."
                />
                <StyledExperience>
                    <section>
                        <div>
                            <p>
                                {' '}
                                We love serving our patients and we are always looking for feedback.
                                We strive to provide 5-star service and hope that your experience
                                matched our mission.
                            </p>
                            <p>
                                If for any reason it didn’t, please let us know right away. If we
                                did meet your expectations, we really would appreciate you leaving
                                us a review online.
                            </p>
                            <p>
                                It means the world to us and we really appreciate you taking the
                                time.
                            </p>
                            <p>
                                Please select the button below that best reflects your experience:
                            </p>
                        </div>
                        <StaticImage
                            src="../images/review.jpg"
                            alt="flcc logo"
                            placeholder="none"
                            quality={100}
                        />
                    </section>
                    <div className="buttons">
                        <a
                            target="__blank"
                            rel="noopener noreferrer"
                            href="https://www.google.com/maps/place/Bloom+La+Vie+Health/@39.2695413,-94.4550041,17z/data=!3m2!4b1!5s0x87c055b11d48a2ab:0xd04e730038dae43e!4m6!3m5!1s0x87c0555de6a2c143:0x8394281b70bd592e!8m2!3d39.2695413!4d-94.4524238!16s%2Fg%2F11qn3khxtg?entry=ttu"
                        >
                            <StaticImage
                                src="../images/green-button.png"
                                alt="green button"
                                placeholder="none"
                                quality={100}
                            />
                        </a>
                        <Link to="/experience-feedback/">
                            <StaticImage
                                src="../images/brown-button.png"
                                alt="green button"
                                placeholder="none"
                                quality={100}
                            />
                        </Link>
                    </div>
                </StyledExperience>
            </DefaultLayout>
        </main>
    );
};

export default Experience;

export const query = graphql`
    query ExperienceHeroPicture {
        allFile(filter: { relativePath: { eq: "experience.jpg" } }) {
            edges {
                node {
                    id
                    childImageSharp {
                        gatsbyImageData(quality: 100, placeholder: NONE)
                    }
                }
            }
        }
    }
`;
